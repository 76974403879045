
.options-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-end;
  padding-bottom: 25px;
}
.options-container-refresh {
  display: flex;
  align-items: flex-end;
  gap: 7px;
  cursor: pointer;
  flex-direction: column;
}
.options-container p {
  font-size: 12px;
  font-family: 'BwGradualMedium';
  color: var(--verdeObscuro40Percent);
}
.options-container-refresh img {
  width: 22px;
}
.options-container-fulfill {
  position: relative;
  top: 30px;
}
.options-container-fulfill button {
  cursor: pointer;
  width: 114px;
  height: 32px;
  border-radius: 30px;
  border: none;
  background-color: var(--verdeTryp);
  color: white;
  font-size: 12px;
  font-family: 'BwGradualBold';
}
