
.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid var(--verdeTryp);
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}