
.container {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}
.container-left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.container-left p {
  font-size: 18px;
  font-family: 'AvenirMedium';
  padding-top: 5px;
}
.container-left img {
  width: 35px;
  height: 36px;
}
.container-right {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}
.container-right p {
  font-size: 18px;
  font-family: 'AvenirMedium';
  color: rgba(41, 58, 48, 0.6);
}
.container-right .line-icon {
  width: 2px;
  height: 50px;
}
.container-right .arrow-icon {
  width: 9px;
  height: 6px;
}