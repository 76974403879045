
.order-container {
  border-radius: 5px;
  margin-top: 20px;
  position: relative;
}
.info-container {
  /*border: 1px solid red;*/
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;
}
.info-container-address {
  /*border: 1px solid gray;*/
}
.info-container-address-name {
  position: absolute;
  top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.info-container-address-name h2 {
  font-size: 17px;
  font-weight: 500;
  font-family: 'BwGradualMedium';
}
.info-container-address h3 {
  font-size: 18px;
  font-family: 'BwGradualMedium';
  font-weight: 400;
}
.info-container-address p {
  font-size: 14px;
  margin-top: 5px;
  font-family: 'AvenirMedium';
  color: var(--verdeObscuro40Percent);
}
.info-container-address span {
  color: black;
}
.info-container-order {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.info-container-order-name {
  font-size: 20px;
  font-family: 'BwGradualMedium';
  color: var(--verdeObscuro);
  font-weight: 600;
  z-index: 100
}
.info-container-order-note {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 40px;
}
.info-container-order-note p {
  font-size: 12px;
  font-family: 'AvenirMedium';
}
.info-container-order-note h3 {
  font-size: 12px;
  font-family: 'AvenirBlack';
  text-align: end;
  margin-top: 5px;
  max-width: 400px;
}
.info-container-order-articles {
  display: flex;
  margin-top: 15px;
}
.info-container-order-articles p {
  font-size: 12px;
  font-family: 'AvenirMedium';
}
.info-container-order-articles span {
  font-size: 16px;
}
.info-container-order-date {
  font-size: 10px;
  font-family: 'AvenirRoman';
  margin-top: 3px;
}
.info-container-order-status {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 5px
}
.info-container-order-status p {
  font-size: 12px;
  font-family: 'BwGradualMedium';
}
.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.green-circle {
  background-color: var(--verdeTryp);
}
.orange-circle {
  background-color: #F5912B;
}
.info-container-order-fulfill {
  margin-top: 15px;
  display: flex;
  gap: 8px
}
.info-container-order-fulfill input {
  padding: 5px 20px;
  display: flex;
  width: 203px;
  height: 19px;
  border-radius: 30px;
  border: 0.5px solid rgba(41, 58, 48, 0.6);
  outline: none;
  font-family: 'AvenirMedium';
}
.info-container-order-fulfill button {
  width: 77px;
  border-radius: 30px;
  border: none;
  color: white;
  font-size: 14px;
  font-family: 'BwGradualBold';
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.items-container {
  margin-top: 40px;
  padding-bottom: 20px;
}
.line-items {
  width: 100%;
}
.line-items th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  padding: 8px;
  font-size: 14px;
  font-family: 'AvenirMedium';
}
.line-items td {
  font-size: 13px;
  font-family: 'AvenirRoman';
  font-weight: 100;
  padding: 8px;
  width: 70px;
  /*
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  */
}
.info-container-address a {
  text-decoration: underline;
  font-family: 'AvenirRoman';
  color: blue;
  font-size: 12px;
  cursor: pointer;
}