
.main-container {
  min-height: 100vh;
  height: 100%;
  background-color: #F1F1F1;
}
.main-container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
}