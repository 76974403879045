
.login-container {
  background-color: white;
  width: 386px;
  height: 380px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.login-container img {
  width: 36px;
  height: 36px;
  margin-top: 50px;
}
.login-container p {
  font-size: 20px;
  font-family: 'BwGradualMedium';
  margin-top: 20px;
}
.login-container input {
  margin-top: 10px;
  width: 223px;
  height: 30px;
  border-radius: 30px;
  border: 0.5px solid rgba(41, 58, 48, 0.6);
  outline: none;
  padding: 0px 20px;
  font-family: 'AvenirMedium';
  padding-top: 5px;
}
.login-container button {
  background-color: var(--verdeTryp);
  color: white;
  width: 114px;
  height: 32px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-family: 'BwGradualBold';
  font-size: 14px;
}