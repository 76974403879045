
.settings-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.settings-container h1 {
  font-size: 16px;
  font-family: 'AvenirMedium';
  color: rgba(41, 58, 48, 0.4);
}
.settings-container .buttons {
  display: flex;
  gap: 10px;
}
.settings-container .buttons button {
  width: 205px;
  height: 58px;
  font-family: 'BwGradualBold';
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
.active-option-btn {
  border: 1px solid #00BE5E;
  color: #00BE5E;
  background-color: rgba(0, 117, 51, 0.1);
}
.inactive-option-btn {
  border: 1px solid rgba(41, 58, 48, 0.2);
  background-color: rgba(41, 58, 48, 0.2);
  color: #FFFFFF;
}