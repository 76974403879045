
.image-modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.product-img {
  width: 400px;
  height: 400px;
}
.close-img {
  width: 70px;
  cursor: pointer;
}