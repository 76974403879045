* {
  margin: 0;
  padding: 0;
}

/* Fonts */
@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirLTStd-Black"),
    url("./fonts/Avenir/AvenirLTStd-Black.otf") format("opentype");
}
@font-face {
  font-family: "AvenirMedium";
  src: local("AvenirLTStd-Medium"),
    url("./fonts/Avenir/AvenirLTStd-Medium.otf") format("opentype");
}
@font-face {
  font-family: "AvenirRoman";
  src: local("AvenirLTStd-Roman"),
    url("./fonts/Avenir/AvenirLTStd-Roman.otf") format("opentype");
}
@font-face {
  font-family: "BwGradualMedium";
  src: local("BwGradual-Medium"),
    url("./fonts/BwGradual/BwGradual-Medium.otf") format("opentype");
}
@font-face {
  font-family: "BwGradualBold";
  src: local("BwGradual-Bold"),
    url("./fonts/BwGradual/BwGradual-Bold.otf") format("opentype");
}

/* variables */
:root {
  --verdeObscuro: #36463d;
  --verdeTryp: #02bf60;
  --verdeTryp10Percent: #d9e4de;
  --softGrey: #F1F1F1;
  --verdeObscuro40Percent: #abb0ad;
  --verdeObscuro20Percent: #c9ccca;
  --magenta: #DF1E61
}

/*  Base colors */
.bg-white {
  background-color: #FFFFFF
}

.font-bw {
  font-family: 'AvenirRoman';
}
