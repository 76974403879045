
.modal-container {
  position: absolute;
  right: 0px;
  top: 90px;
  width: 296px;
  height: 146px;
  z-index: 10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 15px;
}
.modal-container-time {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px
}
.modal-container-time p {
  font-size: 14px;
  font-family: 'AvenirMedium';
}
.modal-container-logout p {
  font-size: 12px;
  color: var(--magenta);
  font-family: 'AvenirMedium';
  cursor: pointer;
}